export const environment = {
  production: false,
  hmr: false,
  firebase: {
    apiKey: 'AIzaSyAfhokOeRNuZyK7Su1CKt_MWPmx_5rpQUE',
    authDomain: 'sitescript-dd178.firebaseapp.com',
    databaseURL: 'https://sitescript-dd178.firebaseio.com',
    projectId: 'sitescript-dd178',
    storageBucket: 'sitescript-dd178.appspot.com',
    messagingSenderId: '356071401632',
    appId: '1:356071401632:web:f5d51d9f29f659d9a4c1c5',
    measurementId: 'G-7PWYSDGTE0',
  },
};
