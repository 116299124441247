<div class="h-screen flex flex-row overflow-hidden">
  <ui-app-navbar
    *ngIf="isAuthenticated$ | async"
    [sideNavClosed]="navigationOpenedStatus$ | async"
    (mouseenter)="onAppNavbarMouseEnter()"
    (mouseleave)="onAppNavbarMouseLeave()"
    class="hidden md:flex md:flex-shrink-0">
  </ui-app-navbar>
  <div class="relative inset-0 flex flex-auto flex-col">
    <ui-application-toolbar
      *ngIf="isAuthenticated$ | async"
      [sideNavClosed]="navigationOpenedStatus$ | async"
      [photoURL]="(user$ | async).photoURL"
      (signOut)="signOut()"
      (toggleSideNav)="onToggleSideNav()">
    </ui-application-toolbar>


    <button (click)="addModalNotification()">Open Modal</button>
    <button (click)="addAlertNotification()">Open Alert</button>
    <router-outlet></router-outlet>

    <!-- Application Loading Spinner -->
    <div
      *ngIf="isLoading | async"
      class="absolute z-10 inset-0 bg-gray-300">
      <ui-clipped-circle-spinner class="z-10 absolute opacity-75" style="top: 50%; right: 52%;"></ui-clipped-circle-spinner>
    </div>
    <!-- End: Application Loading Spinner -->

    <!-- Notifications -->
    <ng-container *ngTemplateOutlet="topToasts"></ng-container>
    <ng-container *ngTemplateOutlet="topSnacks"></ng-container>
    <ng-container *ngTemplateOutlet="topAlerts"></ng-container>
    <ng-container *ngTemplateOutlet="bottomToasts"></ng-container>
    <ng-container *ngTemplateOutlet="bottomSnacks"></ng-container>
    <ng-container *ngTemplateOutlet="bottomAlerts"></ng-container>
    <!-- End: Notifications -->

    <!-- Application Modal -->
    <ng-container *ngTemplateOutlet="modalAlerts"></ng-container>
    <!-- End: Application Modal -->
  </div>
</div>

<!-- Partials -->
<ng-template #topToasts>
  <ui-alert-notification
    alertPosition="tr"
    [alertNotifications]="trNotifications$ | async"
    (removeNotification)="removeAlertNotification($event)"
  ></ui-alert-notification>
</ng-template>

<ng-template #topSnacks>
  <ui-alert-notification
    alertPosition="tc"
    [alertNotifications]="tcNotifications$ | async"
    (removeNotification)="removeAlertNotification($event)"
  ></ui-alert-notification>
</ng-template>

<ng-template #topAlerts>
  <ui-alert-notification
    alertPosition="tfw"
    [alertNotifications]="tfwNotifications$ | async"
    (removeNotification)="removeAlertNotification($event)"
  ></ui-alert-notification>
</ng-template>

<ng-template #bottomToasts>
  <ui-alert-notification
    alertPosition="br"
    [alertNotifications]="brNotifications$ | async"
    (removeNotification)="removeAlertNotification($event)"
  ></ui-alert-notification>
</ng-template>

<ng-template #bottomSnacks>
  <ui-alert-notification
    alertPosition="bc"
    [alertNotifications]="bcNotifications$ | async"
    (removeNotification)="removeAlertNotification($event)"
  ></ui-alert-notification>
</ng-template>

<ng-template #bottomAlerts>
  <ui-alert-notification
    alertPosition="bfw"
    [alertNotifications]="bfwNotifications$ | async"
    (removeNotification)="removeAlertNotification($event)"
  ></ui-alert-notification>
</ng-template>

<ng-template #modalAlerts>
  <ui-modal-notification
    [modalNotifications]="modalNotifications$ | async"
    (removeNotification)="removeModalNotification($event)"
  ></ui-modal-notification>
</ng-template>
