import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import {
  ModalNotification,
  ModalButtonOptions,
  AlertStatusEnum,
  IconClassEnum,
  NotificationEnum,
  ModalDisplayClass,
} from '@site-script-shared-entities';

@Component({
  selector: 'ui-modal-notification',
  templateUrl: './modal-notification.component.html',
  styleUrls: ['./modal-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalNotificationComponent implements OnInit {
  @Input() modalNotifications: ModalNotification[] = [
    {
      title: 'ERROR! ',
      message:
        'Donec ullamcorper nulla non metus auctor fringilla. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Donec id elit non mi porta gravida at eget metus.',
      statusType: AlertStatusEnum.ERROR,
      iconClass: IconClassEnum.ERROR,
      notificationType: NotificationEnum.MODAL,
      displayClass: ModalDisplayClass.FADE_IN_AND_SCALE_UP,
      dismissable: true,
      showDismissIcon: true,
      dismissButtonOptions: {
        action: (modalNotification: ModalNotification) => {
          console.log('Closing all the shitz');
        },
      },
      buttonOptions: [
        {
          label: 'Ok',
          buttonClasses:
            'bg-red-600 text-base leading-6 font-medium text-white hover:bg-red-500 focus:border-red-700 focus:shadow-outline-red',
          action: () => {
            console.log('completed action my nigga');
          },
        },
        {
          label: 'Cacel',
          buttonClasses:
            'border-gray-300 bg-white text-base leading-6 font-medium text-gray-700 hover:text-gray-500 focus:border-blue-300',
          action: () => {
            console.log('completed action my nigga');
          },
        },
      ],
      data: {
        foo: 'bar',
      },
    },
  ];
  @Output() removeNotification = new EventEmitter<ModalNotification>();

  constructor() {}

  ngOnInit(): void {}

  onRemoveNotification(modalNotification: ModalNotification) {
    this.removeNotification.emit(modalNotification);
  }

  onActionClick(modalNotification: ModalNotification, btnOption: ModalButtonOptions) {
    if (btnOption.action) {
      btnOption.action(modalNotification);
    }
  }
}
