import { SignInWithEmailAndPasswordCredentials, SocialType, User } from '@site-script-shared-entities';
import { AuthService } from '@site-script/data/api';

export namespace AuthenticationActions {
  export class SignInWithEmailAndPassword {
    static readonly type = '[Auth] SignInWithEmailAndPassword';
    constructor(public payload: SignInWithEmailAndPasswordCredentials) {}
  }

  export class SignUpWithSocial {
    // TODO: If payload from provider auth varies between social, break this
    // out into separate functions.
    static readonly type = '[Auth] SignUpWithSocial';
    constructor(public payload: SocialType) {}
  }

  export class SetUser {
    static readonly type = '[Auth] SetUser';
    constructor(public payload: User) {}
  }

  export class SignOut {
    static readonly type = '[Auth] SignOut';
  }
}
