export enum AlertStatusEnum {
  ERROR = 'error',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  LOCK = 'lock',
  UNLOCK = 'unlock',
  DOWNLOAD = 'download',
  CHECK = 'check',
  LINK = 'link',
}

export enum IconClassEnum {
  SUCCESS = 'check-circle',
  ERROR = 'times-circle',
  WARNING = 'exclamation-circle',
  INFO = 'info-circle',
}

export enum NotificationEnum {
  TOP_TOAST = 'tr',
  TOP_SNACK = 'tc',
  TOP_ALERT = 'tfw',
  BOTTOM_TOAST = 'br',
  BOTTOM_SNACK = 'bc',
  BOTTOM_ALERT = 'bfw',
  MODAL = 'modal',
}

export enum ModalDisplayClass {
  FADE_IN_AND_SCALE_UP = 'md-effect-1',
  SLIDE_FROM_THE_RIGHT = 'md-effect-2',
  SLIDE_FROM_THE_BOTTOM = 'md-effect-3',
  FLIP_HORIZONTAL = 'md-effect-8',
  FLIP_VERTICAL = 'md-effect-9',
  FLIP_SIGN = 'md-effect-10',
  JUST_ME = 'md-effect-12',
}

export interface AlertNotification {
  id?: string;
  title: string;
  message: string;
  icon?: string;
  actionLabel?: string;
  explicitlyHideIcon?: boolean; // ?;
  dismissable?: boolean;
  dismissAfterAction?: boolean;
  show?: boolean;
  duration?: number;
  statusType: AlertStatusEnum;
  notificationType: NotificationEnum;
  iconClass?: IconClassEnum | string;
  action?: (notification: AlertNotification) => void;
}

export interface ModalButtonOptions {
  label?: string;
  iconClass?: string;
  buttonClasses?: string;
  action: (modalNotification: ModalNotification) => void;
}
export interface ModalOptions {
  displayClass: ModalDisplayClass;
  dismissable: boolean;
  showDismissIcon: boolean;
  dismissButtonOptions?: ModalButtonOptions;
  buttonOptions: ModalButtonOptions[];
}

export interface ModalNotification extends AlertNotification {
  displayClass: ModalDisplayClass;
  dismissable: boolean;
  showDismissIcon: boolean;
  dismissButtonOptions?: ModalButtonOptions;
  buttonOptions: ModalButtonOptions[];
  data?: any;
}
