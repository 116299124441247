import { APP_INITIALIZER, enableProdMode, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { NgxsModule, StateContext } from '@ngxs/store';
import { NgxsDispatchPluginModule } from '@ngxs-labs/dispatch-decorator';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsHmrLifeCycle, NgxsHmrSnapshot as Snapshot } from '@ngxs/hmr-plugin';

import { SharedUIModule } from '@site-script/shared/ui';
import { ApplicationState } from '@site-script/data/application-state';
import { AuthenticationState } from '@site-script/data/authentication-state';
import { LoadingSpinnerService } from '@site-script/shared/services';

import { environment } from '../environments/environment';
import { routes } from './app-routing.module';
import { AppComponent } from './app.component';
import { IsLoadingService } from '../core/services/is-loading.service';

// TODO: Selectively import FB functions.
import { analytics, auth, firestore, performance, storage } from 'firebase/app';

// const fbPerformance = performance();
// const fbAnalytics = analytics();

export function initApp(http: HttpClient, loadingSpinnerService: LoadingSpinnerService) {
  // TODO: Move to file
  // TODO: Tie in with App Init Action
  return () => {
    return http
      .get('https://api.openweathermap.org/data/2.5/weather?q=orlando&appid=458fc9129d27a22493bd3e0d3665b684')
      .toPromise()
      .then((resp) => {
        loadingSpinnerService.removeStaticSpinner();
      });
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(routes, { enableTracing: !environment.production }),

    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    AngularFireAuthModule,

    NgxsModule.forRoot([ApplicationState, AuthenticationState], { developmentMode: !environment.production }),
    NgxsDispatchPluginModule.forRoot(),
    // NgxsStoragePluginModule.forRoot({
    //   key: ['application', 'auth.token'],
    // }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      name: 'Site Script',
      disabled: environment.production,
    }),
    NgxsLoggerPluginModule.forRoot({
      disabled: environment.production,
    }),
    SharedUIModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      multi: true,
      deps: [HttpClient, LoadingSpinnerService],
    },
    IsLoadingService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule implements NgxsHmrLifeCycle<Snapshot> {
  public hmrNgxsStoreOnInit(ctx: StateContext<Snapshot>, snapshot: Partial<Snapshot>) {
    ctx.patchState(snapshot);
  }

  public hmrNgxsStoreBeforeOnDestroy(ctx: StateContext<Snapshot>): Partial<Snapshot> {
    return ctx.getState();
  }
}
