import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingSpinnerService {
  private renderer: Renderer2;

  constructor(private _renderer: RendererFactory2) {
    this.renderer = _renderer.createRenderer(null, null);
  }

  removeStaticSpinner() {
    this.renderer.addClass(document.body, 'spinner-transition');
    timer(600).subscribe((_) => {
      // Remove the <body> classes for the static spinner.
      this.renderer.removeClass(document.body, 'spinner-transition');
      this.renderer.removeClass(document.body, 'loading');

      try {
        // Remove the static spinner. We no longer need it.
        document.querySelector('body > .clipped-circle-spinner').remove();
      } catch (e) {
        console.log(e);
      }
    });
  }

  show(): void {
    // TODO: Implement showing and hiding spinner for content area.
  }

  hide(): void {
    // TODO: Implement showing and hiding spinner for content area.
  }
}
