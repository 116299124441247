import { Sequence } from './sequence.interface';
import { IsBoolean, IsInt, IsMongoId, IsString, IsObject } from 'class-validator';

export class Course implements Sequence {
  @IsString()
  @IsMongoId()
  id: string;

  @IsInt({ message: 'seqNo must be numeric' })
  seqNo: number;

  @IsString({ always: false })
  url: string;

  @IsString()
  iconUrl: string;

  @IsString()
  categories: string;

  @IsString()
  courseListIcon: string;

  @IsString()
  longDescription: string;

  @IsString()
  category: string;

  @IsInt()
  lessonsCount: number;

  @IsInt()
  price: number;

  @IsBoolean()
  promo: boolean;

  @IsString()
  description: string;

  @IsObject()
  titles: { description: string; longDescription: string };
}

// const foo: Course = {
//   seqNo: 1
// };

// const bar: Course = {
//   seqNo: 2
// };

// this.compareSequenceType(foo, bar);

export function compareSequenceType(c1: Sequence, c2: Sequence) {
  const compare = c1.seqNo - c2.seqNo;

  if (compare > 0) {
    return 1;
  } else if (compare < 0) {
    return -1;
  }

  return 0;
}
