import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';

import { AuthenticationState, AuthenticationActions } from '@site-script/data/authentication-state';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private store: Store, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean | UrlTree {
    const isAuthenticated = this.store.selectSnapshot(AuthenticationState.isAuthenticated);

    if (isAuthenticated) {
      // console.log(next);
      return true;
    }

    return false;
  }
}
