import { Action, Selector, State, StateContext, NgxsOnInit } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { tap } from 'rxjs/operators';

import { AuthenticationActions } from './authentication.actions';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from '@site-script/data/api';
import { User } from '@site-script-shared-entities';

export interface AuthStateModel {
  token: string | null;
  email: string | null;
  loaded: boolean;
  user: User;
}

export enum AuthRoutes {
  LOGIN = '/auth/login',
  DEFAULT = '/',
}

@State<AuthStateModel>({
  name: 'authentication',
  defaults: {
    token: null,
    email: null,
    loaded: false,
    user: null,
  },
})
@Injectable()
export class AuthenticationState implements NgxsOnInit {
  private originUrl: string;

  @Selector()
  static token(state: AuthStateModel): string | null {
    return state.token;
  }

  @Selector()
  static isAuthenticated(state: AuthStateModel): boolean {
    return !!state.token;
  }

  @Selector()
  static user(state: AuthStateModel): User | null {
    return state.user;
  }

  constructor(
    private angularFireAuth: AngularFireAuth,
    private authService: AuthService,
    private location: Location,
    private router: Router,
  ) {
    this.initialize();
  }

  /**
    Get locations path in order to redirect user to intended route after authenticating.
    If the intended route is the login route, redite user to the default route after authenticating.
  */
  private initialize() {
    this.originUrl = this.location.path();

    if (this.originUrl.indexOf(AuthRoutes.LOGIN) > -1) {
      this.originUrl = AuthRoutes.DEFAULT;
    }
  }

  /**
  Subscribe to authState and trigger action when ever it's updated. Will trigger setting/resetting state details after signIn, signUp, and signOut.
  */
  ngxsOnInit(ctx: StateContext<User>) {
    this.angularFireAuth.authState.subscribe((user) =>
      ctx.dispatch(new AuthenticationActions.SetUser(this.authService.getUserFromFirebase(user))),
    );
  }

  @Action(AuthenticationActions.SetUser)
  setUser(ctx: StateContext<AuthStateModel>, action: AuthenticationActions.SetUser) {
    const state = ctx.getState();

    if (this.angularFireAuth.auth.currentUser) {
      this.angularFireAuth.auth.currentUser.getIdToken().then((token) => {
        ctx.setState({
          ...state,
          user: action.payload,
          loaded: true,
          token: token,
        });

        this.router.navigateByUrl(this.originUrl);
        this.originUrl = '';
      });
    } else {
      /**
      Reset the state if currentUser is undefined in auth.
      */
      ctx.setState({
        token: null,
        email: null,
        user: null,
        loaded: false,
      });

      this.router.navigate([AuthRoutes.LOGIN]);
    }
  }

  @Action(AuthenticationActions.SignInWithEmailAndPassword)
  signInWithEmailAndPassword(
    ctx: StateContext<AuthStateModel>,
    action: AuthenticationActions.SignInWithEmailAndPassword,
  ) {
    return this.authService.signInWithEmailAndPassword(action.payload).pipe(
      tap((result) => {
        console.log(result);
      }),
    );
  }

  @Action(AuthenticationActions.SignUpWithSocial)
  signUpWithSocial(ctx: StateContext<AuthStateModel>, action: AuthenticationActions.SignUpWithSocial) {
    return this.authService.signUpWithSocial(action.payload).pipe(
      tap((result) => {
        console.log(result);
      }),
    );
  }

  /* Execute signOut api request. Subscription to authState will trigger update in the state. */
  @Action(AuthenticationActions.SignOut)
  signOut(ctx: StateContext<AuthStateModel>) {
    return this.authService.signOut();
  }
}
