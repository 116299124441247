import { Injectable } from '@angular/core';
import { ImmutableContext, ImmutableSelector } from '@ngxs-labs/immer-adapter';
import { Action, Selector, State, StateContext, createSelector } from '@ngxs/store';
import { AlertNotification, NotificationEnum, ModalNotification } from '@site-script-shared-entities';
import { NavigationActions, NotificationActions } from './application.actions';

export interface ApplicationStateModel {
  loading: boolean;
  navigationState: {
    opened: boolean;
    keepOpened: boolean;
  };
  alertNotifications: AlertNotification[];
  modalNotifications: ModalNotification[];
}

@State<ApplicationStateModel>({
  name: 'application',
  defaults: {
    loading: true,
    navigationState: {
      opened: true,
      keepOpened: true,
    },
    alertNotifications: [],
    modalNotifications: [],
  },
})
@Injectable()
export class ApplicationState {
  @Selector()
  static navigationOpenedStatus(state: ApplicationStateModel): boolean {
    return state.navigationState.opened;
  }

  @Selector()
  static navigationKeepOpenedStatus(state: ApplicationStateModel): boolean {
    return state.navigationState.keepOpened;
  }

  @Selector()
  static modalNotifications(state: ApplicationStateModel): ModalNotification[] {
    return state.modalNotifications;
  }

  static alertNotificationsByType(type: NotificationEnum) {
    return createSelector([ApplicationState], (state: ApplicationStateModel) => {
      if (!type) return state.alertNotifications;
      return state.alertNotifications.filter((n) => n.notificationType === type);
    });
  }

  @Action(NavigationActions.ToggleSideNav)
  @ImmutableContext()
  toggleSideNav({ setState, getState }: StateContext<ApplicationStateModel>): void {
    setState((state: ApplicationStateModel) => {
      state.navigationState.opened = !state.navigationState.opened;
      return state;
    });
  }

  @Action(NavigationActions.CloseSideNav)
  @ImmutableContext()
  closeSideNav({ setState, getState }: StateContext<ApplicationStateModel>) {
    setState((state: ApplicationStateModel) => {
      state.navigationState.opened = false;
      return state;
    });
  }

  @Action(NavigationActions.OpenSideNav)
  @ImmutableContext()
  openSideNav({ setState, getState }: StateContext<ApplicationStateModel>) {
    setState((state: ApplicationStateModel) => {
      state.navigationState.opened = true;
      return state;
    });
  }

  /**
   * Modal Notification Action Handlers
   */
  @Action(NotificationActions.AddModalNotification)
  @ImmutableContext()
  addModalNotification(
    { setState, getState }: StateContext<ApplicationStateModel>,
    { notification }: NotificationActions.AddModalNotification,
  ) {
    setState((state: ApplicationStateModel) => {
      state.modalNotifications.push(notification);
      return state;
    });
  }

  @Action(NotificationActions.RemoveModalNotification)
  @ImmutableContext()
  removeModalNotification(
    { setState, getState }: StateContext<ApplicationStateModel>,
    { notification }: NotificationActions.RemoveModalNotification,
  ) {
    setState((state: ApplicationStateModel) => {
      state.modalNotifications[
        state.modalNotifications.findIndex(
          (alertNotification: ModalNotification) => alertNotification.id === notification.id,
        )
      ].show = false;

      return state;
    });

    // Run after .Xsec delay
    setTimeout(() => {
      setState((state: ApplicationStateModel) => {
        state.modalNotifications.splice(
          state.modalNotifications.findIndex(
            (alertNotification: ModalNotification) => alertNotification.id === notification.id,
          ),
          1,
        );

        return state;
      });
    }, 750);
  }

  /**
   * Alert Notification Action Handlers
   */
  @Action(NotificationActions.AddAlertNotification)
  @ImmutableContext()
  addAlertNotification(
    { dispatch, setState, getState }: StateContext<ApplicationStateModel>,
    { notification }: NotificationActions.AddAlertNotification,
  ) {
    if (notification.duration) {
      setTimeout(() => {
        dispatch(new NotificationActions.RemoveAlertNotification(notification));
      }, notification.duration);
    }

    setState((state: ApplicationStateModel) => {
      state.alertNotifications.push(notification);
      return state;
    });
  }

  @Action(NotificationActions.RemoveAlertNotification)
  @ImmutableContext()
  removeAlertNotification(
    { setState, getState }: StateContext<ApplicationStateModel>,
    { notification }: NotificationActions.RemoveAlertNotification,
  ) {
    setState((state: ApplicationStateModel) => {
      state.alertNotifications[
        state.alertNotifications.findIndex(
          (alertNotification: AlertNotification) => alertNotification.id === notification.id,
        )
      ].show = false;
      return state;
    });

    // Run after .Xsec delay
    setTimeout(() => {
      // Remove from the state array after CSS transition
      setState((state: ApplicationStateModel) => {
        state.alertNotifications.splice(
          state.alertNotifications.findIndex(
            (alertNotification: AlertNotification) => alertNotification.id === notification.id,
          ),
          1,
        );
        return state;
      });
    }, 750);
  }
}
