import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { from } from 'rxjs';

import { SignInWithEmailAndPasswordCredentials, User, SocialType } from '@site-script-shared-entities';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private afs: AngularFirestore, private afAuth: AngularFireAuth, private http: HttpClient) {}

  signInWithEmailAndPassword(credentials: SignInWithEmailAndPasswordCredentials) {
    return from(this.afAuth.auth.signInWithEmailAndPassword(credentials.email, credentials.password));
    // .catch(function(error) {
    //   // Handle Errors here.
    //   const errorCode = error.code;
    //   const errorMessage = error.message;

    //   if (errorCode === 'auth/wrong-password') {
    //     alert('Wrong password.');
    //   } else {
    //     alert(errorMessage);
    //   }

    //   console.log(error);
    // });
  }

  signUpWithSocial(social: SocialType) {
    // TODO: If payload from provider auth varies between social, break this
    // out into separate functions.
    switch (social) {
      case 'google':
        return from(this.googleSignin());
        break;
      case 'facebook':
        // TODO: implement
        console.log('TODO: implement');
        break;
      case 'apple':
        // TODO: implement
        console.log('TODO: implement');
        break;
    }
  }

  getUserFromFirebase(user: firebase.UserInfo | null): User | null {
    if (user) {
      return {
        uid: user.uid,
        displayName: user.displayName || '',
        email: user.email || '',
        photoURL: user.photoURL || '',
      };
    } else {
      return null;
    }
  }

  signOut() {
    return from(this.afAuth.auth.signOut());
  }

  private async googleSignin() {
    const provider = new auth.GoogleAuthProvider();
    const credential: auth.UserCredential = await this.afAuth.auth.signInWithPopup(provider);
    return this.updateUserData(credential);
  }

  private async updateUserData(credential: auth.UserCredential) {
    // Sets user data to firestore on login
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${credential.user.uid}`);
    const profile: any = credential.additionalUserInfo.profile || null;
    const data = {
      uid: credential.user.uid,
      displayName: credential.user.displayName,
      email: credential.user.email,
      photoURL: credential.user.photoURL,
      firstName: profile ? profile.given_name : '',
      lastName: profile ? profile.family_name : '',
    };

    return userRef.set(data, { merge: true });
  }
}
