import { v4 as uuid } from 'uuid';
import { AlertNotification, ModalNotification } from '@site-script-shared-entities';

export namespace NavigationActions {
  export class OpenSideNav {
    static readonly type = '[Navigation] Open Side Nav';
  }

  export class CloseSideNav {
    static readonly type = '[Navigation] Close Side Nav';
  }

  export class ToggleSideNav {
    static readonly type = '[Navigation] Toggle Side Nav';
  }
}

export namespace NotificationActions {
  export class AddModalNotification {
    static readonly type = '[Notification] Add Modal Notification';
    constructor(public notification: ModalNotification) {
      if (!notification.id) notification.id = uuid();

      // Default show flag.
      notification.show = true;
    }
  }

  export class RemoveModalNotification {
    static readonly type = '[Notification] Remove Modal Notification';
    constructor(public notification: ModalNotification) {}
  }

  export class AddAlertNotification {
    static readonly type = '[Notification] Add Alert Notification';
    constructor(public notification: AlertNotification) {
      if (!notification.duration) notification.duration = 8000000;
      if (!notification.id) notification.id = uuid();
      if (notification.action && !notification.actionLabel) notification.actionLabel = 'Got it!';

      // Default show flag.
      notification.show = true;
    }
  }

  export class RemoveAlertNotification {
    static readonly type = '[Notification] Remove Alert Notification';
    constructor(public notification: AlertNotification) {}
  }
}
