import { Directive, Input, HostListener, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[appAvatar]',
})
export class DefaultImageDirective implements OnInit {
  private defaultImg: string;

  @HostListener('error') onErrorrr() {
    this.element.nativeElement.src = this.defaultImg;
  }

  constructor(private element: ElementRef) {}

  ngOnInit(): void {
    this.defaultImg = `https://api.adorable.io/avatars/400/${this.hashCode(this.element.nativeElement.src)}.png`;
  }

  hashCode(s: string): number {
    let hash = s.split('').reduce(function(a, b) {
      // tslint:disable-next-line: no-bitwise
      a = (a << 5) - a + b.charCodeAt(0);
      // tslint:disable-next-line: no-bitwise
      return a & a;
    }, 0);

    if (hash < 0) hash *= -1;
    return hash;
  }
}
