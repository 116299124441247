import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { AlertNotification, NotificationEnum, AlertStatusEnum } from '@site-script-shared-entities';

@Component({
  selector: 'ui-alert-notification',
  templateUrl: './alert-notification.component.html',
  styleUrls: ['./alert-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertNotificationComponent implements OnInit {
  @Input() alertPosition = NotificationEnum.TOP_ALERT;
  @Input() alertNotifications: AlertNotification[] = [];
  @Output() removeNotification = new EventEmitter<AlertNotification>();

  constructor() {}

  ngOnInit(): void {}

  onRemoveNotification(alertNotification: AlertNotification) {
    this.removeNotification.emit(alertNotification);
  }

  getClassByPositionAndStatus(pos: NotificationEnum, { show: show, statusType }: AlertNotification) {
    console.log('FETCHING STYLE CLASS FOR BG *********************');
    const dismissClass = !show ? 'notyf__toast--disappear' : '';

    if (pos === NotificationEnum.TOP_ALERT || pos === NotificationEnum.BOTTOM_ALERT) {
      return `${dismissClass} notyf__toast__bg--${statusType}`;
    }

    return `${dismissClass} notyf__toast__bg--none`;
  }
}
