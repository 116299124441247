import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode, NgModuleRef } from '@angular/core';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.hmr) {
  import('@ngxs/hmr-plugin').then((plugin) => {
    plugin.hmr(AppModule, bootstrap).catch((err: Error) => console.error('Dang it:', err));
  });
} else {
  bootstrap().catch((err: Error) => console.log('Fudge Man:', err));
}
