import { Component, OnInit, Output, EventEmitter, Input, ChangeDetectionStrategy } from '@angular/core';
import { User } from '@site-script-shared-entities';
import { Observer } from 'rxjs';

@Component({
  selector: 'ui-application-toolbar',
  templateUrl: './application-toolbar.component.html',
  styleUrls: ['./application-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationToolbarComponent implements OnInit {
  open = false;

  @Input() photoURL: String;
  @Input() sideNavClosed = false;
  @Output() toggleSideNav = new EventEmitter<boolean>();
  @Output() signOut = new EventEmitter<boolean>();
  constructor() {}

  ngOnInit(): void {}

  handleToggleSideNav() {
    this.toggleSideNav.emit(true);
  }

  toggleProfileMenu() {
    this.open = !this.open;
  }

  closeProfileMenu() {
    this.open = false;
  }

  onSignOut() {
    this.signOut.emit(true);
  }
}
