<div class="relative">
  <div
    *ngFor="let modalNotification of modalNotifications"
    [ngClass]="{'md-show': modalNotification.show}"
    class="md-modal {{modalNotification.displayClass || 'md-effect-12'}} md-overlay z-10 fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">
    <div class="md-content">
      <!-- <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div> -->

      <div  class="relative bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6">
        <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
          <button (click)="onRemoveNotification(modalNotification)" type="button" class="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150">
            <!-- Close Icon: TODO fa fa-time -->
            <i class="fa fa-times"></i>
          </button>
        </div>
        <div class="sm:flex sm:items-start">
          <!-- Alert Icon: TODO enum -->
          <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <svg class="h-6 w-6 text-red-600" stroke="currentColor" fill="none" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"/>
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              {{modalNotification.title}} {{modalNotification.show}}
            </h3>
            <div class="mt-2 text-sm leading-5 text-gray-500">
              {{modalNotification.message}}
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <span
            *ngFor="let btnOption of modalNotification.buttonOptions"
            class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
            <button
              type="button"
              (click)="onActionClick(modalNotification, btnOption)"
              class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 shadow-sm focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5 {{btnOption.buttonClasses}}">
              {{btnOption.label}}
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>