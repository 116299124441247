import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { AppNavbarComponent } from './app-navbar/app-navbar.component';
import { NavSideBarComponent } from './nav-side-bar/nav-side-bar.component';
import { ApplicationToolbarComponent } from './application-toolbar/application-toolbar.component';
import { ClippedCircleSpinnerComponent } from './clipped-circle-spinner/clipped-circle-spinner.component';

import { SharedDirectivesModule } from '@site-script/shared/directives';
import { AlertNotificationComponent } from './alert-notification/alert-notification.component';
import { ModalNotificationComponent } from './modal-notification/modal-notification.component';

export const featureClassList = 'flex flex-1 flex-col overflow-auto min-h-0 bg-gray-100';
@NgModule({
  declarations: [
    ApplicationToolbarComponent,
    AppNavbarComponent,
    ClippedCircleSpinnerComponent,
    NavSideBarComponent,
    AlertNotificationComponent,
    ModalNotificationComponent,
  ],
  imports: [CommonModule, SharedDirectivesModule, RouterModule],
  exports: [
    ApplicationToolbarComponent,
    AppNavbarComponent,
    ClippedCircleSpinnerComponent,
    NavSideBarComponent,
    AlertNotificationComponent,
    ModalNotificationComponent,
  ],
})
export class SharedUIModule {}
