import { Routes } from '@angular/router';
import { AuthGuard } from '../core/guards/authentication.guard';
import { LoggedInGuard } from '../core/guards/logged-in.guard';

export const routes: Routes = [
  {
    // path: 'course-list',
    path: '',
    loadChildren: () =>
      import('@site-script/features/dashboard/src/lib/features-dashboard.module').then(
        (m) => m.FeaturesDashboardModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'faqs',
    loadChildren: () =>
      import('@site-script/features/faqs/src/lib/features-faqs.module').then((m) => m.FeaturesFaqsModule),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('@site-script/features/auth/src/lib/features-auth.module').then((m) => m.FeaturesAuthModule),
    canActivate: [LoggedInGuard],
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('@site-script/features/profile/src/lib/features-profile.module').then((m) => m.FeaturesProfileModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'courses',
    loadChildren: () =>
      import('@site-script/features/course/src/lib/features-course.module').then((m) => m.FeaturesCourseModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'customers',
    loadChildren: () =>
      import('@site-script/features/customers/src/lib/features-customers.module').then(
        (m) => m.FeaturesCustomersModule,
      ),
    canActivate: [AuthGuard],
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];
