<!-- Static sidebar for desktop -->
<div class="flex flex-col w-64 transition ease-in-out duration-150"
  [ngClass]="{'w-64': !sideNavClosed, 'w-20': sideNavClosed}">
  <div
    class="flex flex-row items-center h-16 flex-shrink-0 bg-gray-900"
    [ngClass]="{
      'justify-between pl-2 pt-10 pb-10': !sideNavClosed,
      'justify-center pl-0 pt-4 pb-4': sideNavClosed
    }">
    <img *ngIf="sideNavClosed" class="h-8 w-auto" src="assets/images/logos/workflow-mark-on-brand.svg" alt="Workflow" />
    <img *ngIf="!sideNavClosed" class="h-8 w-auto" src="assets/images/logos/workflow-logo-on-dark.svg" alt="Workflow" />
  </div>
  <div class="h-0 flex-1 flex flex-col overflow-y-auto">
    <nav class="flex-1 py-4 bg-gray-800">
      <a
        *ngFor="let nav of [1,2,3,4,5,6,7,8,9,10,11,12,13,14]; let i = index"
        [routerLink]="['/']"
        class="group flex items-center px-2 py-4 text-sm leading-5 font-medium text-white focus:outline-none focus:bg-gray-700 hover:text-white hover:bg-gray-700 transition ease-in-out duration-150"
        [ngClass]="{
          'bg-gray-900 hover:bg-gray-900': i === 0,
          'justify-center': sideNavClosed
        }">
        <svg
          [ngClass]="{
            'mr-0': sideNavClosed,
            'mr-3': !sideNavClosed
          }"
          class="h-6 w-6 text-gray-300 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" stroke="currentColor" fill="none" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l9-9 9 9M5 10v10a1 1 0 001 1h3a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1h3a1 1 0 001-1V10M9 21h6"/>
        </svg>
        <span *ngIf="!sideNavClosed">Dashboard {{i}}</span>
      </a>
      <!--
        <a [routerLink]="['/customers']" class="mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition ease-in-out duration-150">
        <svg class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" stroke="currentColor" fill="none" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"/>
        </svg>
        Customers
      </a>
      <a [routerLink]="['/courses']" class="mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition ease-in-out duration-150">
        <svg class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" stroke="currentColor" fill="none" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"/>
        </svg>
        Courses
      </a>
      <a [routerLink]="['/profile']" class="mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition ease-in-out duration-150">
        <svg class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" stroke="currentColor" fill="none" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"/>
        </svg>
        Profile
      </a>
      <a href="#" class="mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition ease-in-out duration-150">
        <svg class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" stroke="currentColor" fill="none" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"/>
        </svg>
        Documents
      </a>
      <a href="#" class="mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition ease-in-out duration-150">
        <svg class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" stroke="currentColor" fill="none" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"/>
        </svg>
        Reports
      </a>
    -->
    </nav>
  </div>

  <!-- TODO: Extract to footer component. -->
  <div class="flex flex-col bg-gray-700">
      <span class="text-xs px-2 pt-2 text-gray-400">
        &copy; 2020 Workflow, Inc. All rights reserved.
      </span>
      <div class="flex px-2 py-2 md:order-2">
        <div>
          <span class="sr-only">Facebook</span>
          <button type="button" class="mr-4 text-lg leading-5 text-gray-500 hover:text-gray-400 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition duration-150 ease-in-out">
            <i class="fab fa-google"></i>
          </button>
        </div>
        <div>
          <span class="sr-only">Facebook</span>
          <button type="button" class="mr-4 text-lg leading-5 text-gray-500 hover:text-gray-400 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition duration-150 ease-in-out">
            <i class="fab fa-facebook-f"></i>
          </button>
        </div>
        <div>
          <span class="sr-only">Facebook</span>
          <button type="button" class="mr-4 text-lg leading-5 text-gray-500 hover:text-gray-400 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition duration-150 ease-in-out">
            <i class="fab fa-apple"></i>
          </button>
        </div>
      </div>
  </div>
</div>
