import { Sequence } from './sequence.interface';

export interface Lesson extends Sequence {
  id: number;
  description?: string;
  duration: string;
  seqNo: number;
  courseId: number;
}

export function compareLessons(l1: Lesson, l2: Lesson) {
  const compare = l1.courseId - l2.courseId;

  if (compare > 0) {
    return 1;
  } else if (compare < 0) {
    return -1;
  }

  return l1.seqNo - l2.seqNo;
}
