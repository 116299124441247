<div class="notyf relative alert-notification alert-notification--{{alertPosition}}">
  <div
    class="notyf__toast notyf__toast--{{alertNotification.statusType}}"
    [ngClass]="getClassByPositionAndStatus(alertPosition, alertNotification)"
    *ngFor="let alertNotification of alertNotifications; let i=index;">
    <button
      class="absolute z-50 absolute focus:outline-none notyf__toast__close notyf__toast__close--{{alertNotification.statusType}}"
      (click)="onRemoveNotification(alertNotification)">
      <i class="fa fa-times"></i>
    </button>
    <div class="notyf__wrapper">
      <div class="notyf__icon">
        <i
          *ngIf="alertNotification.iconClass"
          class="fa fa-{{alertNotification.iconClass}} text-white" aria-hidden="true"></i>
      </div>
      <div class="notyf__message flex flex-row">
        <div class="flex-1 pr-2" [innerHTML]="alertNotification.message"></div>
        <button
          type="button"
          class="underline hover:bg-gray focus:outline-none outline-none"
          *ngIf="alertNotification.action != null"
          (click)="alertNotification.action(alertNotification)">
          {{alertNotification.actionLabel}}
        </button>
      </div>
    </div>
    <div class="notyf__ripple notyf__ripple--{{alertNotification.statusType}} "></div>
  </div>
</div>