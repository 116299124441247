import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { Observer } from 'rxjs';

@Component({
  selector: 'ui-app-navbar',
  templateUrl: './app-navbar.component.html',
  styleUrls: ['./app-navbar.component.scss'],
})
export class AppNavbarComponent implements OnInit {
  @Input() sideNavClosed = false;
  @Output() toggleSideNav = new EventEmitter<boolean>();
  constructor() {}

  ngOnInit(): void {}

  handleToggleSideNav() {
    this.toggleSideNav.emit(true);
  }
}
