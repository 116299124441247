import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { fuseAnimations } from '@site-script/shared/fuse';
import {
  User,
  AlertStatusEnum,
  AlertNotification,
  IconClassEnum,
  NotificationEnum,
  ModalDisplayClass,
  ModalNotification,
} from '@site-script-shared-entities';
import { ApplicationState, NavigationActions, NotificationActions } from '@site-script/data/application-state';
import { AuthenticationState, AuthenticationActions } from '@site-script/data/authentication-state';
import { IsLoadingService } from '../core/services/is-loading.service';
import { transition, state, trigger, style, animate } from '@angular/animations';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';

@Component({
  selector: 'site-script-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @Select(ApplicationState.navigationOpenedStatus) navigationOpenedStatus$: Observable<boolean>;
  @Select(ApplicationState.alertNotificationsByType(NotificationEnum.TOP_TOAST)) trNotifications$: Observable<
    AlertNotification[]
  >;
  @Select(ApplicationState.alertNotificationsByType(NotificationEnum.TOP_SNACK)) tcNotifications$: Observable<
    AlertNotification[]
  >;
  @Select(ApplicationState.alertNotificationsByType(NotificationEnum.TOP_ALERT)) tfwNotifications$: Observable<
    AlertNotification[]
  >;
  @Select(ApplicationState.alertNotificationsByType(NotificationEnum.BOTTOM_TOAST)) brNotifications$: Observable<
    AlertNotification[]
  >;
  @Select(ApplicationState.alertNotificationsByType(NotificationEnum.BOTTOM_SNACK)) bcNotifications$: Observable<
    AlertNotification[]
  >;
  @Select(ApplicationState.alertNotificationsByType(NotificationEnum.BOTTOM_ALERT)) bfwNotifications$: Observable<
    AlertNotification[]
  >;
  @Select(ApplicationState.modalNotifications) modalNotifications$: Observable<ModalNotification[]>;
  @Select(AuthenticationState.isAuthenticated) isAuthenticated$: Observable<boolean>;
  @Select(AuthenticationState.user) user$: Observable<User>;

  isLoading: Observable<boolean>;
  open = false;

  private mockNotify: { [key: string]: AlertNotification } = {
    error: {
      title: 'ERROR! ',
      message: 'TOP_TOAST: Sed posuere consectetur est at lobortis.',
      statusType: AlertStatusEnum.ERROR,
      iconClass: IconClassEnum.ERROR,
      notificationType: NotificationEnum.TOP_TOAST,
      duration: 2000,
      show: true,
      action: (n: AlertNotification) => {
        console.log(n);
        this.removeAlertNotification(n);
      },
    },
    info: {
      title: 'INFO! ',
      message: 'TOP_TOAST: Sed posuere consectetur est at lobortis.',
      statusType: AlertStatusEnum.INFO,
      iconClass: IconClassEnum.INFO,
      notificationType: NotificationEnum.TOP_TOAST,
      duration: 2000,
      show: true,
    },
    info2: {
      title: 'INFO! ',
      message: 'TOP_SNACK: Sed posuere consectetur est at lobortis.',
      statusType: AlertStatusEnum.INFO,
      iconClass: IconClassEnum.INFO,
      notificationType: NotificationEnum.TOP_SNACK,
      duration: 2000,
      show: true,
    },
    info3: {
      title: 'INFO! ',
      message: 'TOP_ALERT: Sed posuere consectetur est at lobortis.',
      statusType: AlertStatusEnum.INFO,
      iconClass: IconClassEnum.INFO,
      notificationType: NotificationEnum.TOP_ALERT,
      duration: 2000,
      show: true,
    },
    success: {
      title: 'SUCCESS! ',
      message: 'BOTTOM_TOAST: Sed posuere consectetur est at lobortis.',
      statusType: AlertStatusEnum.SUCCESS,
      iconClass: IconClassEnum.SUCCESS,
      notificationType: NotificationEnum.BOTTOM_TOAST,
      duration: 2000,
      show: true,
    },
    warning: {
      title: 'WARNING! ',
      message: 'BOTTOM_SNACK: Sed posuere consectetur est at lobortis.',
      statusType: AlertStatusEnum.WARNING,
      iconClass: IconClassEnum.WARNING,
      notificationType: NotificationEnum.BOTTOM_SNACK,
      duration: 2000,
      show: true,
    },
    warning2: {
      title: 'WARNING! ',
      message: 'BOTTOM_ALERT: Sed posuere consectetur est at lobortis.',
      statusType: AlertStatusEnum.WARNING,
      iconClass: IconClassEnum.WARNING,
      notificationType: NotificationEnum.BOTTOM_ALERT,
      duration: 2000,
      show: true,
    },
  };

  private mockModal: ModalNotification = {
    title: 'ERROR! ',
    message:
      'Donec ullamcorper nulla non metus auctor fringilla. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Donec id elit non mi porta gravida at eget metus.',
    statusType: AlertStatusEnum.ERROR,
    iconClass: IconClassEnum.ERROR,
    notificationType: NotificationEnum.MODAL,
    displayClass: ModalDisplayClass.FADE_IN_AND_SCALE_UP,
    dismissable: true,
    showDismissIcon: true,
    show: true,
    dismissButtonOptions: {
      action: (modalNotification: ModalNotification) => {
        console.log('Closing all the shitz');
      },
    },
    buttonOptions: [
      {
        label: 'Ok',
        buttonClasses:
          'bg-red-600 text-base leading-6 font-medium text-white hover:bg-red-500 focus:border-red-700 focus:shadow-outline-red',
        action: () => {
          console.log('completed action my nigga');
        },
      },
      {
        label: 'Cacel',
        buttonClasses:
          'border-gray-300 bg-white text-base leading-6 font-medium text-gray-700 hover:text-gray-500 focus:border-blue-300',
        action: () => {
          console.log('completed action my nigga');
        },
      },
    ],
    data: {
      foo: 'bar',
    },
  };

  applicationSettings = {
    title: 'ng-tailwind',
    sidebarOpen: true,
    open: true,
    authenticated: true,
  };

  constructor(
    private http: HttpClient,
    private isLoadingService: IsLoadingService,
    private router: Router,
    private store: Store,
  ) {}

  ngOnInit() {
    // TODO: Move to Routing Facade
    this.isLoading = this.isLoadingService.isLoading$();

    this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationStart ||
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError,
        ),
      )
      .subscribe((event) => {
        // If it's the start of navigation, `add()` a loading indicator
        if (event instanceof NavigationStart) {
          this.isLoadingService.add();
          return;
        }

        // Else navigation has ended, so `remove()` a loading indicator
        this.isLoadingService.remove();
      });
  }

  @Dispatch() addAlertNotification = (notification?: AlertNotification) => {
    notification = JSON.parse(JSON.stringify(this.mockNotify.error));
    return new NotificationActions.AddAlertNotification(notification);
  };

  @Dispatch() addModalNotification = (notification?: ModalNotification) => {
    notification = JSON.parse(JSON.stringify(this.mockModal));
    return new NotificationActions.AddModalNotification(notification);
  };

  @Dispatch() removeAlertNotification = (notification: AlertNotification) =>
    new NotificationActions.RemoveAlertNotification(notification);

  @Dispatch() removeModalNotification = (notification: ModalNotification) =>
    new NotificationActions.RemoveModalNotification(notification);

  addAlertNotification2(notification?: AlertNotification) {
    notification = JSON.parse(JSON.stringify(this.mockNotify.error));
    // TODO: Move to Notification Facade
    this.store.dispatch(new NotificationActions.AddAlertNotification(notification));
  }

  removeAlertNotification2(notification: AlertNotification) {
    // TODO: Move to Notification Facade
    this.store.dispatch(new NotificationActions.RemoveAlertNotification(notification));
  }

  addModalNotification2(notification?: ModalNotification) {
    notification = JSON.parse(JSON.stringify(this.mockModal));
    // TODO: Move to Notification Facade
    this.store.dispatch(new NotificationActions.AddModalNotification(notification));
  }

  removeModalNotification2(notification: ModalNotification) {
    // TODO: Move to Notification Facade
    this.store.dispatch(new NotificationActions.RemoveModalNotification(notification));
  }

  onToggleSideNav() {
    // TODO: Move to Navigation Facade
    this.store.dispatch(new NavigationActions.ToggleSideNav());
  }

  onAppNavbarMouseEnter() {
    // TODO: Move to Navigation Facade
    if (this.getNavOpenedState()) {
      this.store.dispatch(new NavigationActions.OpenSideNav());
    }
  }

  onAppNavbarMouseLeave() {
    // TODO: Move to Navigation Facade
    const navKeepClosedState = this.getNavKeepOpenedState();
    if (navKeepClosedState) {
      this.store.dispatch(new NavigationActions.CloseSideNav());
    }
  }

  signOut() {
    // TODO: Move to Auth Facade
    this.store.dispatch(new AuthenticationActions.SignOut());
  }

  private getNavOpenedState(): boolean {
    // TODO: Move to Navigation Facade
    return this.store.selectSnapshot(ApplicationState.navigationOpenedStatus);
  }

  private getNavKeepOpenedState(): boolean {
    // TODO: Move to Navigation Facade
    return this.store.selectSnapshot(ApplicationState.navigationKeepOpenedStatus);
  }
}
